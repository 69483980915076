import { graphql } from 'gatsby'
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import React from 'react'

import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const Eula = () => {
  const { language } = useI18next()
  const { t } = useTranslation('pages', { keyPrefix: 'terms.eula' })

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderTitle title={t('title')} />
          <ContentSubPage>
            <p>
              <Trans i18nKey="intro.text.0" t={t}>
                Valid from: January 01, 2020
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.1" t={t}>
                Please read this End-User License Agreement
                (&quot;Agreement&quot;) carefully before clicking the &quot;I
                Agree&quot; button, downloading or using Flagis
                (&quot;Application&quot;).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.2" t={t}>
                By clicking the &quot;I Agree&quot; button, downloading or using
                the Application, you are agreeing to be bound by the terms and
                conditions of this Agreement.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.3" t={t}>
                This Agreement is a legal agreement between you (either an
                individual or a single entity) and Flagis s.r.o. and it governs
                your use of the Application made available to you by Flagis
                s.r.o..
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.4" t={t}>
                If you do not agree to the terms of this Agreement, do not click
                on the &quot;I Agree&quot; button and do not download or use the
                Application.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.5" t={t}>
                The Application is licensed, not sold, to you by Flagis s.r.o.
                for use strictly in accordance with the terms of this Agreement.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="license.title" t={t}>
                License
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="license.text.0" t={t}>
                Flagis s.r.o. grants you a revocable, non-exclusive,
                non-transferable, limited license to download, install and use
                the Application solely for your personal, non-commercial
                purposes strictly in accordance with the terms of this
                Agreement.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="restrictions.title" t={t}>
                Restrictions
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="restrictions.text.0" t={t}>
                You agree not to, and you will not permit others to:
              </Trans>
            </p>
            <ul>
              <li>
                <p>
                  <Trans i18nKey="restrictions.text.1" t={t}>
                    license, sell, rent, lease, assign, distribute, transmit,
                    host, outsource, disclose or otherwise commercially exploit
                    the Application or make the Application available to any
                    third party.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="restrictions.text.2" t={t}>
                    copy or use the Application for any purpose other than as
                    permitted under the above section &quot;License&quot;.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="restrictions.text.3" t={t}>
                    modify, make derivative works of, disassemble, decrypt,
                    reverse compile or reverse engineer any part of the
                    Application.
                  </Trans>
                </p>
              </li>
              <li>
                <p>
                  <Trans i18nKey="restrictions.text.4" t={t}>
                    remove, alter or obscure any proprietary notice (including
                    any notice of copyright or trademark) of Flagis s.r.o. or
                    its affiliates, partners, suppliers or the licensors of the
                    Application.
                  </Trans>
                </p>
              </li>
            </ul>
            <h3>
              <Trans i18nKey="intellectualProperty.title" t={t}>
                Intellectual Property
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="intellectualProperty.text.0" t={t}>
                The Application, including without limitation all copyrights,
                patents, trademarks, trade secrets and other intellectual
                property rights are, and shall remain, the sole and exclusive
                property of Flagis s.r.o..
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="yourSuggestions.title" t={t}>
                Your Suggestions
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="yourSuggestions.text.0" t={t}>
                Any feedback, comments, ideas, improvements or suggestions
                (collectively, &quot;Suggestions&quot;) provided by you to
                Flagis s.r.o. with respect to the Application shall remain the
                sole and exclusive property of Flagis s.r.o..
              </Trans>
            </p>
            <p>
              <Trans i18nKey="yourSuggestions.text.1" t={t}>
                Flagis s.r.o. shall be free to use, copy, modify, publish, or
                redistribute the Suggestions for any purpose and in any way
                without any credit or any compensation to you.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="modifications.title" t={t}>
                Modifications to Application
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="modifications.text.0" t={t}>
                Flagis s.r.o. reserves the right to modify, suspend or
                discontinue, temporarily or permanently, the Application or any
                service to which it connects, with or without notice and without
                liability to you.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="updates.title" t={t}>
                Updates to Application
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="updates.text.0" t={t}>
                Flagis s.r.o. may from time to time provide enhancements or
                improvements to the features/functionality of the Application,
                which may include patches, bug fixes, updates, upgrades and
                other modifications (&quot;Updates&quot;).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="updates.text.1" t={t}>
                Updates may modify or delete certain features and/or
                functionalities of the Application. You agree that Flagis s.r.o.
                has no obligation to (i) provide any Updates, or (ii) continue
                to provide or enable any particular features and/or
                functionalities of the Application to you.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="updates.text.2" t={t}>
                You further agree that all Updates will be (i) deemed to
                constitute an integral part of the Application, and (ii) subject
                to the terms and conditions of this Agreement.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="thirdParty.title" t={t}>
                Third-Party Services
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="thirdParty.text.0" t={t}>
                The Application may display, include or make available
                third-party content (including data, information, applications
                and other products services) or provide links to third-party
                websites or services (&quot;Third-Party Services&quot;).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="thirdParty.text.1" t={t}>
                You acknowledge and agree that Flagis s.r.o. shall not be
                responsible for any Third-Party Services, including their
                accuracy, completeness, timeliness, validity, copyright
                compliance, legality, decency, quality or any other aspect
                thereof. Flagis s.r.o. does not assume and shall not have any
                liability or responsibility to you or any other person or entity
                for any Third-Party Services.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="thirdParty.text.2" t={t}>
                Third-Party Services and links thereto are provided solely as a
                convenience to you and you access and use them entirely at your
                own risk and subject to such third parties&apos; terms and
                conditions.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="privacyPolicy.title" t={t}>
                Privacy Policy
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="privacyPolicy.text.0" t={t}>
                Flagis s.r.o. collects, stores, maintains, and shares
                information about you in accordance with its Privacy Policy,
                which is available at{' '}
                <Link language={language} to="/legal/privacy-policy">
                  https://www.flagis.com/legal/privacy-policy
                </Link>
                . By accepting this Agreement, you acknowledge that you hereby
                agree and consent to the terms and conditions of our Privacy
                Policy.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="termAndTermination.title" t={t}>
                Term and Termination
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="termAndTermination.text.0" t={t}>
                This Agreement shall remain in effect until terminated by you or
                Flagis s.r.o..
              </Trans>
            </p>
            <p>
              <Trans i18nKey="termAndTermination.text.1" t={t}>
                Flagis s.r.o. may, in its sole discretion, at any time and for
                any or no reason, suspend or terminate this Agreement with or
                without prior notice.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="termAndTermination.text.2" t={t}>
                This Agreement will terminate immediately, without prior notice
                from Flagis s.r.o., in the event that you fail to comply with
                any provision of this Agreement. You may also terminate this
                Agreement by deleting the Application and all copies thereof
                from your mobile device or from your computer.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="termAndTermination.text.3" t={t}>
                Upon termination of this Agreement, you shall cease all use of
                the Application and delete all copies of the Application from
                your mobile device or from your computer.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="termAndTermination.text.4" t={t}>
                Termination of this Agreement will not limit any of Flagis
                s.r.o.&apos;s rights or remedies at law or in equity in case of
                breach by you (during the term of this Agreement) of any of your
                obligations under the present Agreement.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="indemnification.title" t={t}>
                Indemnification
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="indemnification.text.0" t={t}>
                You agree to indemnify and hold Flagis s.r.o. and its parents,
                subsidiaries, affiliates, officers, employees, agents, partners
                and licensors (if any) harmless from any claim or demand,
                including reasonable attorneys&apos; fees, due to or arising out
                of your: (a) use of the Application; (b) violation of this
                Agreement or any law or regulation; or (c) violation of any
                right of a third party.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="noWarranties.title" t={t}>
                No Warranties
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="noWarranties.text.0" t={t}>
                The Application is provided to you &quot;AS IS&quot; and
                &quot;AS AVAILABLE&quot; and with all faults and defects without
                warranty of any kind. To the maximum extent permitted under
                applicable law, Flagis s.r.o., on its own behalf and on behalf
                of its affiliates and its and their respective licensors and
                service providers, expressly disclaims all warranties, whether
                express, implied, statutory or otherwise, with respect to the
                Application, including all implied warranties of
                merchantability, fitness for a particular purpose, title and
                non-infringement, and warranties that may arise out of course of
                dealing, course of performance, usage or trade practice. Without
                limitation to the foregoing, Flagis s.r.o. provides no warranty
                or undertaking, and makes no representation of any kind that the
                Application will meet your requirements, achieve any intended
                results, be compatible or work with any other software,
                applications, systems or services, operate without interruption,
                meet any performance or reliability standards or be error free
                or that any errors or defects can or will be corrected.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="noWarranties.text.1" t={t}>
                Without limiting the foregoing, neither Flagis s.r.o. nor any
                Flagis s.r.o.&apos;s provider makes any representation or
                warranty of any kind, express or implied: (i) as to the
                operation or availability of the Application, or the
                information, content, and materials or products included
                thereon; (ii) that the Application will be uninterrupted or
                error-free; (iii) as to the accuracy, reliability, or currency
                of any information or content provided through the Application;
                or (iv) that the Application, its servers, the content, or
                e-mails sent from or on behalf of Flagis s.r.o. are free of
                viruses, scripts, trojan horses, worms, malware, timebombs or
                other harmful components.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="noWarranties.text.2" t={t}>
                Some jurisdictions do not allow the exclusion of or limitations
                on implied warranties or the limitations on the applicable
                statutory rights of a consumer, so some or all of the above
                exclusions and limitations may not apply to you.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="limitation.title" t={t}>
                Limitation of Liability
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="limitation.text.0" t={t}>
                Notwithstanding any damages that you might incur, the entire
                liability of Flagis s.r.o. and any of its suppliers under any
                provision of this Agreement and your exclusive remedy for all of
                the foregoing shall be limited to the amount actually paid by
                you for the Application.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="limitation.text.1" t={t}>
                To the maximum extent permitted by applicable law, in no event
                shall Flagis s.r.o. or its suppliers be liable for any special,
                incidental, indirect, or consequential damages whatsoever
                (including, but not limited to, damages for loss of profits, for
                loss of data or other information, for business interruption,
                for personal injury, for loss of privacy arising out of or in
                any way related to the use of or inability to use the
                Application, third-party software and/or third-party hardware
                used with the Application, or otherwise in connection with any
                provision of this Agreement), even if Flagis s.r.o. or any
                supplier has been advised of the possibility of such damages and
                even if the remedy fails of its essential purpose.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="limitation.text.2" t={t}>
                Some states/jurisdictions do not allow the exclusion or
                limitation of incidental or consequential damages, so the above
                limitation or exclusion may not apply to you.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="severability.title" t={t}>
                Severability
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="severability.text.0" t={t}>
                If any provision of this Agreement is held to be unenforceable
                or invalid, such provision will be changed and interpreted to
                accomplish the objectives of such provision to the greatest
                extent possible under applicable law and the remaining
                provisions will continue in full force and effect.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="waiver.title" t={t}>
                Waiver
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="waiver.text.0" t={t}>
                Except as provided herein, the failure to exercise a right or to
                require performance of an obligation under this Agreement shall
                not effect a party&apos;s ability to exercise such right or
                require such performance at any time thereafter nor shall be the
                waiver of a breach constitute waiver of any subsequent breach.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="amendments.title" t={t}>
                Amendments to this Agreement
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="amendments.text.0" t={t}>
                Flagis s.r.o. reserves the right, at its sole discretion, to
                modify or replace this Agreement at any time. If a revision is
                material we will provide at least 30 days&apos; notice prior to
                any new terms taking effect. What constitutes a material change
                will be determined at our sole discretion.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="amendments.text.1" t={t}>
                By continuing to access or use our Application after any
                revisions become effective, you agree to be bound by the revised
                terms. If you do not agree to the new terms, you are no longer
                authorized to use the Application.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="governingLaw.title" t={t}>
                Governing Law
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="governingLaw.text.0" t={t}>
                The laws of Czech Republic, excluding its conflicts of law
                rules, shall govern this Agreement and your use of the
                Application. Your use of the Application may also be subject to
                other local, state, national, or international laws.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="contact.title" t={t}>
                Contact Information
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="contact.text.0" t={t}>
                If you have any questions about this Agreement, please contact
                us.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="entireAgreement.title" t={t}>
                Entire Agreement
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="entireAgreement.text.0" t={t}>
                The Agreement constitutes the entire agreement between you and
                Flagis s.r.o. regarding your use of the Application and
                supersedes all prior and contemporaneous written or oral
                agreements between you and Flagis s.r.o..
              </Trans>
            </p>
            <p>
              <Trans i18nKey="entireAgreement.text.1" t={t}>
                You may be subject to additional terms and conditions that apply
                when you use or purchase other Flagis s.r.o.&apos;s services,
                which Flagis s.r.o. will provide to you at the time of such use
                or purchase.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="contactUs.title" t={t}>
                Contact Us
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="contactUs.text.0" t={t}>
                If you have any questions about this End-User License Agreement,
                please contact us:
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="contactUs.text.1" t={t}>
                  By email: <a href="mailto:info@flagis.com">info@flagis.com</a>
                </Trans>
              </li>
              <li>
                <Trans i18nKey="contactUs.text.2" t={t}>
                  By visiting this page on our website:{' '}
                  <Link language={language} to="/contact-us">
                    https://www.flagis.com/contact-us
                  </Link>
                </Trans>
              </li>
            </ul>
          </ContentSubPage>
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default Eula

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
